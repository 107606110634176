import React, { useState, useEffect } from "react";
import Carousel from "../../components/Carousel";
import Container from "../../components/Container";
import { useAppData } from "../../context/AppContext";
import Categories from "../../components/Categories";
import HeaderSection from "../../components/section/Header";
import Outlets from "../../components/section/Outlets";
import Footer from "../../components/section/Footer";

const Index = () => {
  const { products, getProducts, loading, setLoading } = useAppData();

  useEffect(() => {
    getProducts({ featured: true }, () => setLoading(false));
  }, []);

  console.log(products);

  return (
    <Container>
      <HeaderSection/>
      <Categories />
      <Carousel />
      {/* <FeatureProducts /> */}
      <Outlets/>
      <Footer/>
    </Container>
  );
};

export default Index;
