import React from "react";
import { Row, Col } from "shards-react";
import { Slide } from "react-slideshow-image";

const Carousel = () => {
  const images = [
    "https://gate.com.ph/store/wp-content/uploads/2022/10/viber_image_2022-10-11_17-49-05-682.jpg",
    "https://gate.com.ph/store/wp-content/uploads/2022/11/horizontal-banner-banner-5.png",
    "https://gate.com.ph/store/wp-content/uploads/2022/10/horizontal-banner-banner-2-1.png",
  ];
  return (
    <Row style={{margin: 0}}>
      <Col md={12} style={{ padding: 20 }}>
        <Slide>
          {images.map((image) => (
            <div className="each-slide-effect">
              <div
                style={{ backgroundImage: `url(${image})`, borderRadius: 5 }}
              ></div>
            </div>
          ))}
        </Slide>
      </Col>
      {/* <Col md={4} style={{ padding: 20, paddingLeft: 0}}>
        <img
          src="https://gate.com.ph/store/wp-content/uploads/2022/09/Claim-the-good-days-you-deserve-and-make-it-even-more-extra-this-GDay-with-bigger-better-and-more-extra-activities-1.png"
          style={{ width: "100%", display: "block", borderRadius: 5, marginBottom: 5, height: "125px"}}
        />
        <img
          src="https://gate.com.ph/store/wp-content/uploads/2022/09/Claim-the-good-days-you-deserve-and-make-it-even-more-extra-this-GDay-with-bigger-better-and-more-extra-activities-1.png"
          style={{ width: "100%", display: "block", borderRadius: 5, height: "120px" }}
        />
      </Col> */}
    </Row>
  );
};

export default Carousel;
