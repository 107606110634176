import React, { useState } from "react";
import { Col, Row, Button } from "shards-react";
import CoverImage from "../../assets/images/cover.png";
import CountUp from "react-countup";
import { useAppData } from "../../context/AppContext";

const Header = () => {
  const { mobileScreen } = useAppData();
  const [brandCount, setBrandCount] = useState(false);
  const [productCount, setProductCount] = useState(false);
  const [outletCount, setOutletCount] = useState(false);

  return (
    <div
      style={{
        backgroundImage: `url(${CoverImage})`,
        height: mobileScreen ? "90vh" : "80vh",
      }}
    >
      <Row
        style={{
          margin: 0,
          height: "100%",
          padding: "5% 10%",
          background: "rgb(0,51,84)",
          background:
            "linear-gradient(50deg, rgba(0,51,84,0.7462587329853816) 40%, rgba(255,255,255,1) 100%)",
        }}
      >
        <Col md={7}>
          <h3
            style={{ color: "#fff", fontWeight: "900", lineHeight: "normal" }}
          >
            Helping businesses grow with our quality and fast-moving products
            since 1997
          </h3>
          <Row style={{ paddingTop: 50 }}>
            <Col md={4} xs={6} sm={6} style={{ padding: 0 }}>
              <h2
                style={{
                  color: "#fff",
                  fontWeight: "600",
                  lineHeight: "normal",
                }}
              >
                <CountUp
                  end={20}
                  duration={5}
                  onEnd={() => setBrandCount(true)}
                />
                {brandCount && "+"}
                <span
                  style={{
                    display: "block",
                    fontSize: "1.0rem",
                    fontWeight: "normal",
                  }}
                >
                  Brand Partners
                </span>
              </h2>
            </Col>
            <Col md={4} xs={6} sm={6} style={{ padding: 0 }}>
              <h2
                style={{
                  color: "#fff",
                  fontWeight: "600",
                  lineHeight: "normal",
                }}
              >
                <CountUp
                  end={100}
                  duration={5}
                  onEnd={() => setProductCount(true)}
                />
                {productCount && "+"}
                <span
                  style={{
                    display: "block",
                    fontSize: "1.0rem",
                    fontWeight: "normal",
                  }}
                >
                  Products
                </span>
              </h2>
            </Col>
            <Col md={4} xs={6} sm={6} style={{ padding: 0 }}>
              <h2
                style={{
                  color: "#fff",
                  fontWeight: "600",
                  lineHeight: "normal",
                }}
              >
                <CountUp
                  end={10000}
                  duration={5}
                  onEnd={() => setOutletCount(true)}
                />
                {outletCount && "+"}
                <span
                  style={{
                    display: "block",
                    fontSize: "1.0rem",
                    fontWeight: "normal",
                  }}
                >
                  Outlets Nationwide
                </span>
              </h2>
            </Col>
          </Row>
          <Button
            outline
            style={{
              border: "3px solid #fff",
              color: "#fff",
              marginTop: 10,
              fontSize: "1.2rem",
            }}
          >
            Partner With Us!
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default Header;
