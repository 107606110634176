module.exports = {
  API: {
    URL: "https://gate.com.ph",
    HEADERS: {
      headers: {
        "Content-Type": "application/json",
        "API-Key": "sasasa",
      },
    },
  },
  BRANDS: [
    {
      id: 39,
      name: "SMART",
      logo: "https://gate.com.ph/wp-content/uploads/2022/05/258-2584282_pldt-wireless-subsidiary-smart-communications-has-posted-smart-1-1.png",
    },

    {
      id: 53,
      name: "Talk 'n Text",
      logo: "https://gate.com.ph/wp-content/uploads/2022/05/kisspng-tnt-logo-brand-manila-clip-art-talk-n-text-logo-1-hd-wallpapers-buzz-5b6314e8866e71.4194157215332200725506.png",
    },
    {
      id: 56,
      name: "DITO",
      logo: "https://gate.com.ph/wp-content/uploads/2022/05/1200px-Dito_Telco_logo.svg_.png",
    },
    {
      id: 40,
      name: "Globe",
      logo: "https://gate.com.ph/wp-content/uploads/2023/01/Globe-Logo-blue-2.png",
    },
    {
      id: 51,
      name: "GOMO",
      logo: "https://gate.com.ph/wp-content/uploads/2022/05/GOMO.png",
    },
    {
      id: 55,
      name: "Republika ng TM",
      logo: "https://gate.com.ph/wp-content/uploads/2023/01/TM-LOGO-4.png",
    },

    {
      id: "",
      name: "Cherry Prepaid",
      logo: "https://gate.com.ph/wp-content/uploads/2022/05/cherry-prepaid.png",
    },
    {
      id: "",
      name: "PLDT",
      logo: "https://gate.com.ph/wp-content/uploads/2022/05/pldt-logo-ver2.png",
    },
    {
      id: 77,
      name: "Cignal",
      logo: "https://gate.com.ph/wp-content/uploads/2022/05/unnamed-1.png",
    },
    {
      id: 78,
      name: "GMA",
      logo: "https://upload.wikimedia.org/wikipedia/en/4/4b/GMA_Affordabox_logo.png",
    },
    {
      id: 79,
      name: "ABS-CBN",
      logo: "https://upload.wikimedia.org/wikipedia/en/thumb/3/37/ABS-CBNTVplus.svg/250px-ABS-CBNTVplus.svg.png",
    },
    {
      id: 87,
      name: "SATLITE",
      logo: "https://gate.com.ph/store/wp-content/uploads/2023/04/USl1R.png",
    },
    {
      id: 88,
      name: "MegaSound",
      logo: "https://cdn.shopify.com/s/files/1/0267/2702/2663/t/9/assets/ELrpoitPhH-logo-png.png?v=1622935381",
    },
    {
      id: "",
      name: "GCash",
      logo: "https://technology.inquirer.net/files/2022/05/GCash-Logo.png",
    },
    {
      id: "",
      name: "ESET",
      logo: "https://gate.com.ph/wp-content/uploads/2022/05/Picture3.png",
    },
  ],
};
