import axios from "axios";
import jQuery from "jquery";
import { API } from "../config";

function useWoocommerce() {
  const wooGet = async (path, config, query) => {
    if (Array.isArray(path)) {
      const requests = path.map((p) =>
        axios.post(
          `${API.URL}/api/woocommerce/get.php`,
          {
            path: query ? p.path + "?" + jQuery.param(query) : p.path,
            merchantID: p.id,
          },
          API.HEADERS
        )
      );

      return axios.all(requests);
    } else if (config) {
      return axios.post(
        `${API.URL}/api/woocommerce/get.php`,
        {
          path: query ? path + "?" + jQuery.param(query) : path,
          merchantID: config.id,
        },
        API.HEADERS
      );
    } else {
      console.log("static");
      return axios.get(path);
    }
  };

  const wooPost = async (path, body, config) => {
    return axios.post(
      `${API.URL}/api/woocommerce/post.php`,
      {
        path: path,
        body: JSON.stringify(body),
        merchantID: config.id,
      },
      API.HEADERS
    );
  };

  return {
    wooGet,
    wooPost,
  };
}

export default useWoocommerce;
