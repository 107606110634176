import React, { useEffect, useState } from "react";
import ProductCard from "../../components/ProductCard";
import { useAppData } from "../../context/AppContext";
import { Row, Col, Button, FormCheckbox } from "shards-react";
import Container from "../../components/Container";
import useQueryURL from "../../hooks/api/useQueryURL";
import { BRANDS } from "../../hooks/config";
import { useNavigate } from "@reach/router";

const Index = () => {
  const { products, getProducts, loading, setLoading } = useAppData();
  const { getQuery } = useQueryURL();

  const categoryID = getQuery("category");
  const brandID = getQuery("brand");

  const [filters, setFilters] = useState([]);

  useEffect(() => {
    setLoading(true);
    if (brandID || categoryID) {
      let ids = `${brandID},${categoryID}`;
      getProducts({ category: ids }, () => setLoading(false));
    } else {
      getProducts({}, () => setLoading(false));
    }

    if (categoryID) {
      setFilters(categoryID.split(",").map((c) => parseInt(c)));
    }
  }, [brandID, categoryID]);

  const navigate = useNavigate();

  return (
    <Container>
      <Row style={{ margin: 0 }}>
        <Col md={12} style={{ padding: 20 }}>
          <h4>Brand Partners</h4>
          {BRANDS.filter((brand) => brand.id !== "").map((brand) => (
            <Button
              outline
              size="md"
              theme="info"
              style={{ marginRight: 10, marginBottom: 10, fontWeight: 600 }}
              onClick={() =>
                navigate(
                  brandID ==  brand.id
                    ? `/catalog?${
                        filters ? `category=${filters.join(",")}` : ""
                      }`
                    : `/catalog?brand=${brand.id}${
                      filters ? `&category=${filters.join(",")}` : ""
                      }`
                )
              }
              className={parseInt(brandID) === brand.id ? "active" : ""}
            >
              {brand.name}
            </Button>
          ))}
        </Col>
        {loading ? (
          <Col md={12}>Loading...</Col>
        ) : (
          <Col md={12}>
            <Row style={{ margin: 0 }}>
              <Col md={3}>
                <h5>Filters</h5>
                <FormCheckbox
                  checked={filters.includes(15)}
                  onChange={() =>
                    setFilters(
                      filters.includes(15)
                        ? filters.filter((f) => f !== 15)
                        : [...filters, 15]
                    )
                  }
                >
                  Prepaid SIM Cards
                </FormCheckbox>
                <FormCheckbox
                  checked={filters.includes(19)}
                  onChange={() =>
                    setFilters(
                      filters.includes(19)
                        ? filters.filter((f) => f !== 19)
                        : [...filters, 19]
                    )
                  }
                >
                  Prepaid Call Cards
                </FormCheckbox>
                <FormCheckbox
                  checked={filters.includes(71)}
                  onChange={() =>
                    setFilters(
                      filters.includes(71)
                        ? filters.filter((f) => f !== 71)
                        : [...filters, 71]
                    )
                  }
                >
                  Prepaid Wifi Devices
                </FormCheckbox>
                <FormCheckbox
                  checked={filters.includes(72)}
                  onChange={() =>
                    setFilters(
                      filters.includes(72)
                        ? filters.filter((f) => f !== 72)
                        : [...filters, 72]
                    )
                  }
                >
                  Digital TV Boxes
                </FormCheckbox>
                <FormCheckbox
                  checked={filters.includes(83)}
                  onChange={() =>
                    setFilters(
                      filters.includes(83)
                        ? filters.filter((f) => f !== 83)
                        : [...filters, 83]
                    )
                  }
                >
                  Electronics
                </FormCheckbox>
                <Button
                  size="sm"
                  onClick={() =>
                    navigate(
                      brandID
                        ? `/catalog?brand=${brandID}&category=${filters.join(
                            ","
                          )}`
                        : `/catalog?category=${filters.join(",")}`
                    )
                  }
                >
                  Submit
                </Button>
              </Col>
              <Col md={9}>
                <Row>
                  {products
                    .map((product) => ({
                      ...product,
                      categoryIDs: product.categories.map((c) => c.id),
                    }))
                    .filter((product) =>
                      brandID
                        ? product.categoryIDs.includes(parseInt(brandID))
                        : product
                    )
                    .map((product) => (
                      <Col
                        md={3}
                        xs={6}
                        sm={6}
                        style={{ margin: 0, marginTop: 10, marginBottom: 10 }}
                      >
                        <ProductCard product={product} />
                      </Col>
                    ))}
                </Row>
              </Col>
            </Row>
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default Index;
