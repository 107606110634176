import { useNavigate } from "@reach/router";
import React from "react";
import { Col, Row } from "shards-react";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <Row style={{ height: "80vh", padding: "5%", margin: 0 }}>
      <Col md={3}>
        <img
          src="https://gate.com.ph/wp-content/uploads/2022/05/long-logo.png"
          style={{ width: "70%" }}
          onClick={() => navigate("/")}
        />
        <p style={{paddingTop: 20}}>
          Established in 1997, Gate Distribution Enterprise Inc., is one of the
          pioneer distributors in the country that delivers prepaid products and
          services over an electronic network to major retailers and chain
          accounts.
        </p>
      </Col>
      <Col md={3}></Col>
      <Col md={3}></Col>
    </Row>
  );
};

export default Footer;
