import axios from "axios";
import { useState } from "react";
import useWoocommerce from "./useWoocommerce";

function useProducts() {
  const { wooGet } = useWoocommerce();

  const merchants = [{ id: 1, name: "Gate Online Store", path: "/products" }];

  const [products, setProducts] = useState([]);

  function getProducts(query, actions) {
    wooGet(merchants, merchants, {
      ...query,
      page: 1,
      per_page: 100,
      orderby: "id",
      status: "publish",
    })
      .then((response) => {
        console.log(response);
        let resp = response
          .map((rs) => ({
            merchant: JSON.parse(rs.config.data),
            data: rs.data.data,
          }))
          .map((rs) =>
            rs.length !== 0
              ? {
                  data: rs.data.map((d) => ({
                    ...d,
                    merchant: { id: rs.merchant.merchantID },
                  })),
                }
              : rs
          );

        let list = [];
        resp.forEach((res) => list.push(...res.data));

        setProducts(list);
        actions();
      })
      .catch(function (error) {
        console.log("error");
      })
      .then(function () {});
  }

  function getProductsByCategory(query, actions) {
    wooGet(merchants, merchants, {
      ...query,
    })
      .then((response) => {
        console.log(response);
        let resp = response
          .map((rs) => ({
            merchant: JSON.parse(rs.config.data),
            data: rs.data.data,
          }))
          .map((rs) =>
            rs.length !== 0
              ? {
                  data: rs.data.map((d) => ({
                    ...d,
                    merchant: { id: rs.merchant.merchantID },
                  })),
                }
              : rs
          );

        let list = [];
        resp.forEach((res) => list.push(...res.data));

        setProducts(list);
        actions();
      })
      .catch(function (error) {
        console.log("error");
      })
      .then(function () {});
  }

  function getProduct(id, merchant, actions) {
    wooGet(`/products?slug=${id}`, { id: merchant }, {})
      .then((response) => {
        actions(response);
      })
      .catch(function (error) {
        actions()
        console.log("error");
      })
      .then(function () {});
  }

  return {
    getProducts,
    getProductsByCategory,
    products,
    getProduct
  };
}

export default useProducts;
