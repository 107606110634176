import React, { useEffect } from "react";
import Container from "../../components/Container";
import { useAppData } from "../../context/AppContext";
import {Row, Col} from "shards-react"
import ProductCard from "../../components/ProductCard";

const Search = () => {
  const { loading, products } = useAppData();

  useEffect(() => {
    if(!products){
        window.location.replace("/")
    }
  }, [products])
  return (
    <Container>
      <Row style={{ padding: 10 }}>
        {loading ? (
          "Loading..."
        ) : (
          <>
            {products.map((product) => (
              <Col
                md={2}
                xs={6}
                sm={6}
                style={{
                  margin: 0,
                  marginTop: 10,
                  marginBottom: 10,
                }}
              >
                <ProductCard product={product} />
              </Col>
            ))}
          </>
        )}
      </Row>
    </Container>
  );
};

export default Search;
