import React, { createContext, useContext, useEffect, useState } from "react";
import useMediaQuery from "use-mediaquery";
import useProducts from "../hooks/api/useProducts";

const Context = createContext();

export function useAppData() {
  return useContext(Context);
}

export const AppContext = ({ children }) => {
  const { products, getProducts, getProductsByCategory, getProduct } =
    useProducts();
  const [loading, setLoading] = useState(false);

  const mobileScreen = useMediaQuery("(max-width: 800px)");

  const [openMenu, setOpenMenu] = useState(false);
  const [requestQuote, setRequestQuote] = useState(false);

  return (
    <Context.Provider
      value={{
        mobileScreen,
        products,
        getProducts,
        getProductsByCategory,
        getProduct,
        loading,
        setLoading,
        openMenu,
        setOpenMenu,
        requestQuote,
        setRequestQuote,
      }}
    >
      {children}
    </Context.Provider>
  );
};
