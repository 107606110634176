import React, { useState } from "react";
import { Button, Col, Row } from "shards-react";
import { useAppData } from "../../context/AppContext";
import Map from "../../assets/images/maps/main.png";
import Map711 from "../../assets/images/maps/711.png";
import MapNCCC from "../../assets/images/maps/nccc.png"

const Outlets = () => {
  const { mobileScreen } = useAppData();

  const PARTNER_OUTLETS = [
    { name: "7-Eleven", logo: "", map: Map711 },
    { name: "NCCC", logo: "", map: MapNCCC },
    { name: "USSC", logo: "", map: Map },
    { name: "AFPCES", logo: "", map: Map },
    { name: "FamilyMart", logo: "", map: Map },
    { name: "AllDay", logo: "", map: Map },
    { name: "Easy Day", logo: "", map: Map },
    { name: "Gaisano Mall", logo: "", map: Map },
    { name: "LCC Mall", logo: "", map: Map },
    { name: "Price Retails", logo: "", map: Map },
    { name: "Shopwise", logo: "", map: Map },
    { name: "Petron", logo: "", map: Map },
    { name: "Total", logo: "", map: Map },
    { name: "LandMark", logo: "", map: Map },
  ];

  const [selectedMap, setSelectedMap] = useState("");

  return (
    <Row
      style={{
        padding: "3% 5%",
        margin: 0
      }}
    >
      <Col md={7}>
        <h2
          style={{ color: "#003354", fontWeight: "900", lineHeight: "normal" }}
        >
          Nation Wide Partner Outlets
        </h2>
        <p style={{ color: "#003354", fontWeight: 500 }}>
          We deliver and distribute our partners' products into the following
          retails outlets nation wide.
        </p>

        {PARTNER_OUTLETS.map((outlet, index) => (
          
            <Button
              outline
              style={{
                marginBottom: 5,
                marginRight: 5,
                fontWeight: 500,
                fontSize: "1rem",
              }}
              size="sm"
              className={selectedMap === outlet.name ? "active" : ""}
              onClick={() =>
                setSelectedMap(selectedMap === outlet.name ? "" : outlet.name)
              }
            >
              {outlet.name}
            </Button>
        ))}
      </Col>
      <Col md={5}>
        <img
          src={
            selectedMap !== ""
              ? PARTNER_OUTLETS.find((outlet) => outlet.name === selectedMap).map
              : Map
          }
          style={{ width: "100%", transition: "2s" }}
        />
      </Col>
    </Row>
  );
};

export default Outlets;
