import React, { useEffect } from "react";
import { useState } from "react";
import {
  Badge,
  Button,
  ButtonGroup,
  ButtonToolbar,
  Col,
  Row,
  Modal,
  ModalBody,
  ModalHeader,
  FormGroup,
  FormInput,
  FormTextarea,
  ModalFooter,
} from "shards-react";
import { useAppData } from "../../context/AppContext";
import ImageGallery from "react-image-gallery";
import Container from "../../components/Container";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartPlus, faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";

const Profile = ({ id, merchant }) => {
  const { getProduct, setLoading, loading, setRequestQuote} = useAppData();

  const [product, setProduct] = useState({});

  useEffect(() => {
    setLoading(true);
    if (id || merchant) {
      getProduct(
        id,
        merchant,
        (res) => setProduct(res.data.data[0]) || setLoading(false)
      );
    }
  }, [id, merchant]);

  console.log(product);

  const [qty, setQty] = useState(1);


  return (
    <Container>
      

      {loading || product.length === 0 ? (
        "Loading..."
      ) : (
        <div style={{ padding: 20 }}>
          {product.length !== 0 && (
            <>
              <Row
                style={{ margin: 0, paddingTop: 20, backgroundColor: "#fff" }}
                className="main-card"
              >
                <Col md={5}>
                  {product && product.images ? (
                    <ImageGallery
                      items={product.images.map((image) => ({
                        original: image.src,
                        thumbnail: image.src,
                      }))}
                    />
                  ) : (
                    "Loading Product Iamges"
                  )}
                </Col>
                <Col md={1}></Col>
                <Col md={6}>
                  <Row>
                    <Col md={12}>
                      <h4 style={{ fontWeight: 600 }}>{product.name}</h4>
                    </Col>

                    {product.on_sale && (
                      <Col md={2}>
                        <strike>&#8369; {product.regular_price}</strike>
                      </Col>
                    )}
                    <Col md={product.on_sale ? 10 : 12}>
                      <h5 style={{ display: "flex" }}>
                        &#8369; {product.price}{" "}
                        {product.on_sale && (
                          <Badge
                            theme="info"
                            style={{
                              fontWeight: 500,
                              fontSize: "10px",
                              marginLeft: 5,
                            }}
                          >
                            {Math.round(
                              ((parseFloat(product.regular_price) -
                                parseFloat(product.sale_price)) /
                                parseFloat(product.regular_price)) *
                                100
                            )}
                            % OFF
                          </Badge>
                        )}
                      </h5>
                    </Col>
                    {/* shipping fee */}
                    {/* <Col md={2} style={{marginBottom: 10}}>Shipping:</Col>
                    <Col md={10}></Col> */}

                    {product.stock_quantity !== 0 ? (
                      <>
                        {/* Quantity */}
                        <Col md={2}>Quantity:</Col>
                        <Col md={10}>
                          <ButtonToolbar>
                            <ButtonGroup size="sm">
                              <Button
                                outline
                                onClick={() => setQty(qty - 1)}
                                disabled={qty === 1}
                                theme="info"
                              >
                                <FontAwesomeIcon icon={faMinus} />
                              </Button>
                              <Button outline theme="info">
                                {qty}
                              </Button>
                              <Button
                                outline
                                onClick={() => setQty(qty + 1)}
                                disabled={qty === product.stock_quantity}
                                theme="info"
                              >
                                <FontAwesomeIcon icon={faPlus} />
                              </Button>
                            </ButtonGroup>
                          </ButtonToolbar>
                        </Col>
                      </>
                    ) : (
                      <Col md={12}>Out of Stock</Col>
                    )}

                    {/* add to cart */}
                    <Col md={12}>
                      {product.stock_quantity !== 0 && (
                        <Button
                          size="md"
                          style={{ backgroundColor: "#003354", marginTop: 20, marginRight: 10 }}
                        >
                          <FontAwesomeIcon icon={faCartPlus} /> Add to cart
                        </Button>
                      )}

                      <Button
                        size="md"
                        theme="info"
                        style={{  marginTop: 20 }}
                        onClick={() => setRequestQuote(true)}
                      >
                        Request a quote
                      </Button>
                    </Col>

                    {/* share links */}
                    <Col md={2} style={{ marginTop: 20 }}>
                      Share:
                    </Col>
                    <Col md={10}></Col>
                  </Row>
                </Col>
              </Row>
              <Row
                style={{ margin: 0, marginTop: 20, backgroundColor: "#fff" }}
              >
                <Col md={12}>
                  <div style={{ paddingTop: 10 }}>
                    <h5>Product Description</h5>
                    <div
                      dangerouslySetInnerHTML={{ __html: product.description }}
                    />
                  </div>
                </Col>
              </Row>
            </>
          )}
        </div>
      )}
    </Container>
  );
};

export default Profile;
