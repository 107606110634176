import React from "react";
import { Router } from "@reach/router";
import LandingPage from "./pages/LandingPage";
import ProductPage from "./pages/Products";
import Search from "./pages/Products/Search";
import { useAppData } from "./context/AppContext";
import { Modal, ModalBody } from "shards-react";
import { Helmet } from "react-helmet";
import ProductProfile from "./pages/Products/Profile";
import ProductCategories from "./pages/Products/Category";
import QuotationForm from "./components/QuotationForm";

const App = () => {
  const { loading } = useAppData();

  return (
    <>
      <Helmet>
        <title>Gate B2B Marketplace</title>
      </Helmet>
      <div>
        {loading && (
          <Modal open={loading} toggle={() => ""} className="loading-modal">
            <ModalBody>Loading ... </ModalBody>
          </Modal>
        )}

        <QuotationForm />

        <Router>
          <LandingPage path="/" />
          <ProductPage path="/catalog" />
          <ProductCategories path="/catalog/:id" />
          <Search path="/search/products" />
          <ProductProfile path="/catalog/:merchant/:id" />
        </Router>
      </div>
    </>
  );
};

export default App;
