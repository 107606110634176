import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { AppContext } from "./context/AppContext";

import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "shards-ui/dist/css/shards.min.css"
import 'react-slideshow-image/dist/styles.css'
import 'react-image-gallery/styles/css/image-gallery.css'

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <AppContext>
      <App />
    </AppContext>
);
