import React, { useEffect, useState } from "react";
import ProductCard from "../../components/ProductCard";
import { useAppData } from "../../context/AppContext";
import { Row, Col } from "shards-react";
import Container from "../../components/Container";

const Category = ({ id }) => {
  const { getProducts, products, loading, setLoading } = useAppData();

  useEffect(() => {
    setLoading(true)
    getProducts({ category: id }, () => setLoading(false));
  }, [id]);

  console.log(products);

  return (
    <Container>
      <Row style={{margin: 0}}>
        {loading ? (
          "Loading..."
        ) : (
          <>
            {products.map((product) => (
              <Col
                md={2}
                xs={6}
                sm={6}
                style={{ margin: 0, marginTop: 10, marginBottom: 10 }}
              >
                <ProductCard product={product} />
              </Col>
            ))}
          </>
        )}
      </Row>
    </Container>
  );
};

export default Category;
