import { useLocation } from "@reach/router";

function useQueryURL() {
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();

  function getQuery(params) {
    return query.get(params);
  }

  function changeParams(url, params, newValue) {
    var href = new URL(url);
    href.searchParams.set(params, newValue);
    return href.toString();
  }

  return {
    getQuery,
    changeParams
  };
}

export default useQueryURL;
