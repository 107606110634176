import React, { useEffect } from "react";
import { useState } from "react";
import {
  Badge,
  Button,
  ButtonGroup,
  ButtonToolbar,
  Col,
  Row,
  Modal,
  ModalBody,
  ModalHeader,
  FormGroup,
  FormInput,
  FormTextarea,
  ModalFooter,
} from "shards-react";
import { useAppData } from "../context/AppContext";

const QuotationForm = () => {
  const { requestQuote, setRequestQuote } = useAppData();

  return (
    <Modal open={requestQuote} toggle={() => setRequestQuote(!requestQuote)}>
      <ModalHeader style={{ padding: "10px 20px" }}>
        Quotation Request Form
      </ModalHeader>

      <ModalBody style={{ padding: "10px 20px" }}>
        <Row>
          <Col md={6}>
            <FormGroup>
              <label
                style={{
                  marginBottom: "0.3rem",
                  fontSize: "10px",
                  fontWeight: 500,
                }}
              >
                FULL NAME
              </label>
              <FormInput placeholder="full name" />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <label
                style={{
                  marginBottom: "0.3rem",
                  fontSize: "10px",
                  fontWeight: 500,
                }}
              >
                COMPANY NAME
              </label>
              <FormInput placeholder="company name" />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <label
                style={{
                  marginBottom: "0.3rem",
                  fontSize: "10px",
                  fontWeight: 500,
                }}
              >
                EMAIL ADDRESS
              </label>
              <FormInput placeholder="email address" />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <label
                style={{
                  marginBottom: "0.3rem",
                  fontSize: "10px",
                  fontWeight: 500,
                }}
              >
                CONTACT NUMBER
              </label>
              <FormInput placeholder="contact number" />
            </FormGroup>
          </Col>
          <Col md={12}>
            <FormGroup>
              <label
                style={{
                  marginBottom: "0.3rem",
                  fontSize: "10px",
                  fontWeight: 500,
                }}
              >
                MESSAGE
              </label>
              <FormTextarea placeholder="message"></FormTextarea>
            </FormGroup>
          </Col>
          <Col md={12}>
            <FormGroup>
              <label
                style={{
                  marginBottom: "0.3rem",
                  fontSize: "10px",
                  fontWeight: 500,
                  textTransform: "uppercase",
                }}
              >
                Upload Request for Quotation File
              </label>
              <FormInput type="file" placeholder="contact number" />
            </FormGroup>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter style={{ padding: "10px 20px" }}>
        <Button style={{ backgroundColor: "#003354" }}>Submit</Button>
        <Button theme="light" onClick={() => setRequestQuote(false)}>Cancel</Button>
      </ModalFooter>
    </Modal>
  );
};

export default QuotationForm;
