import React from "react";
import { Row, Col, Card, CardBody, CardImg } from "shards-react";
import PrepaidSims from "../assets/images/categories/prepaid sims.png";
import PrepaidCards from "../assets/images/categories/prepaid cards.png";
import PrepaidWifi from "../assets/images/categories/prepaid wifi.png";
import PrepaidCable from "../assets/images/categories/cable.png";
import HomeEntertainemnt from "../assets/images/categories/home entertainment.png";
import Gcash from "../assets/images/categories/gcash.png";
import CablePostPaid from "../assets/images/categories/cable-postapaid.png";
import PLDT from "../assets/images/categories/pldt.png";
import ESET from "../assets/images/categories/eset.png";
import { useNavigate } from "@reach/router";

const Categories = () => {
  const CATEGORIES = [
    {
      link: "/catalog?category=15",
      label: "Prepaid SIM Cards",
      cover: PrepaidSims,
      image: "https://gate.com.ph/store/wp-content/uploads/2022/11/1-1.png",
      brands: [
        "https://gate.com.ph/wp-content/uploads/2022/05/258-2584282_pldt-wireless-subsidiary-smart-communications-has-posted-smart-1-1.png",
        "https://gate.com.ph/wp-content/uploads/2022/05/GOMO.png",
        "https://gate.com.ph/wp-content/uploads/2022/05/kisspng-tnt-logo-brand-manila-clip-art-talk-n-text-logo-1-hd-wallpapers-buzz-5b6314e8866e71.4194157215332200725506.png",
        "https://gate.com.ph/wp-content/uploads/2023/01/TM-LOGO-4.png",
        "https://gate.com.ph/wp-content/uploads/2023/01/Globe-Logo-blue-2.png",
        "https://gate.com.ph/wp-content/uploads/2022/05/1200px-Dito_Telco_logo.svg_.png",
        "https://gate.com.ph/wp-content/uploads/2022/05/cherry-prepaid.png",
      ],
    },
    {
      link: "/catalog?category=19",
      label: "Prepaid Load Cards",
      cover: PrepaidCards,
      image: "https://gate.com.ph/store/wp-content/uploads/2022/11/3.png",
      brands: [
        "https://gate.com.ph/wp-content/uploads/2022/05/258-2584282_pldt-wireless-subsidiary-smart-communications-has-posted-smart-1-1.png",
        "https://gate.com.ph/wp-content/uploads/2022/05/kisspng-tnt-logo-brand-manila-clip-art-talk-n-text-logo-1-hd-wallpapers-buzz-5b6314e8866e71.4194157215332200725506.png",
        "https://gate.com.ph/wp-content/uploads/2023/01/TM-LOGO-4.png",
        "https://gate.com.ph/wp-content/uploads/2023/01/Globe-Logo-blue-2.png",
        "https://gate.com.ph/store/wp-content/uploads/2023/04/USl1R.png",
        "https://gate.com.ph/wp-content/uploads/2022/05/unnamed-1.png",
      ],
    },
    {
      link: "/catalog?category=71",
      label: "Prepaid Wifi Devices",
      cover: PrepaidWifi,
      image: "https://gate.com.ph/store/wp-content/uploads/2022/11/2.png",
      brands: [
        "https://gate.com.ph/wp-content/uploads/2022/05/258-2584282_pldt-wireless-subsidiary-smart-communications-has-posted-smart-1-1.png",
        "https://gate.com.ph/wp-content/uploads/2022/05/pldt-logo-ver2.png",
        "https://gate.com.ph/wp-content/uploads/2023/01/Globe-Logo-blue-2.png",
      ],
    },
    {
      link: "/catalog?category=72",
      label: "Cable & Digital TV Devices",
      cover: PrepaidCable,
      image: "https://gate.com.ph/store/wp-content/uploads/2022/11/4.png",
      brands: [
        "https://gate.com.ph/wp-content/uploads/2022/05/unnamed-1.png",
        "https://upload.wikimedia.org/wikipedia/en/4/4b/GMA_Affordabox_logo.png",
        "https://upload.wikimedia.org/wikipedia/en/thumb/3/37/ABS-CBNTVplus.svg/250px-ABS-CBNTVplus.svg.png",
        "https://gate.com.ph/store/wp-content/uploads/2023/04/USl1R.png",
      ],
    },
    {
      link: "/catalog?category=84",
      label: "Home Entertainement",
      cover: HomeEntertainemnt,
      image: "",
      brands: [
        "https://cdn.shopify.com/s/files/1/0267/2702/2663/t/9/assets/ELrpoitPhH-logo-png.png?v=1622935381",
      ],
    },
    {
      label: "GCash Business Account",
      cover: Gcash,
      image:
        "https://gate.com.ph/store/wp-content/uploads/2022/10/Website-Icons-5.png",
      brands: ["https://technology.inquirer.net/files/2022/05/GCash-Logo.png"],
    },
    // {
    //   label: "Cignal Postpaid Plans",
    //   cover: CablePostPaid,
    //   image: "https://gate.com.ph/store/wp-content/uploads/2023/01/gRJxu.png",
    //   brands: ["https://gate.com.ph/wp-content/uploads/2022/05/unnamed-1.png"],
    // },
    {
      label: "Postpaid Plans",
      cover: PLDT,
      image:
        "https://gate.com.ph/store/wp-content/uploads/2022/10/Website-Icons-8.png",
      brands: [
        "https://gate.com.ph/wp-content/uploads/2022/05/pldt-logo-ver2.png",
        "https://gate.com.ph/wp-content/uploads/2022/05/unnamed-1.png",
      ],
    },
    {
      label: "ESET Antivirus",
      cover: ESET,
      image:
        "https://gate.com.ph/store/wp-content/uploads/2022/10/Website-Icons-8.png",
      brands: ["https://gate.com.ph/wp-content/uploads/2022/05/Picture3.png"],
    },
  ];

  const navigate = useNavigate()
  return (
    <Row style={{ padding: 20, margin: 0 }}>
      {/* <Col md={12} style={{ padding: 0, paddingTop: 10, paddingBottom: 10 }}>
        CATEGORIES
      </Col> */}
      {CATEGORIES.map((category) => (
        <Col
          md={3}
          xs={6}
          sm={6}
          style={{
            textAlign: "center",
            border: "0px solid gray",
            padding: 10,
            height: "300px",
          }}
        >
          <Card style={{ height: "100%" }} onClick={() => navigate(category.link)}>
            <CardImg
              src={category.cover}
              top
              style={{ height: "50%", objectFit: "cover" }}
            />
            <CardBody style={{ padding: 5, height: "50%" }}>
              <p
                style={{
                  fontSize: "1.0rem",
                  fontWeight: 700,
                  marginBottom: 10,
                }}
              >
                {category.label}
              </p>
              {category.brands.map((brand) => (
                <img
                  src={brand}
                  style={{ width: category.brands.length >= 3 ? "20%" : "40%" }}
                />
              ))}
              {/* <img src={category.image} style={{ width: "60px" }} /> */}
            </CardBody>
          </Card>
        </Col>
      ))}
    </Row>
  );
};

export default Categories;
