import React, { useState } from "react";
import { Card, CardImg, CardBody } from "shards-react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { useNavigate } from "@reach/router";

import "react-tooltip/dist/react-tooltip.css";
import useCustomFunctions from "../hooks/api/useCustomFunctions";

const ProductCard = (props) => {
  const navigate = useNavigate();
  const {numberWithCommas} = useCustomFunctions()
  return (
    <Card
      onClick={() =>
        navigate(`/catalog/${props.product.merchant.id}/${props.product.slug}`)
      }
    >
      <CardImg src={props.product.images[0].src} top />
      <CardBody style={{ padding: 10 }}>
        <p
          style={{ margin: 0, fontSize: "0.70rem", fontWeight: 500 }}
          id={props.product.slug}
        >
          {props.product.name.slice(0, 20)}...
        </p>
        {props.product.slug && (
          <ReactTooltip
            anchorId={props.product.slug}
            place="bottom"
            variant="info"
            content={props.product.name}
            style={{ zIndex: 9999999 }}
          />
        )}
        <p style={{ fontWeight: 600, fontSize: "1rem", color: "red" }}>
          {props.product.on_sale && (
            <>
              <strike style={{ color: "gray", fontWeight: "normal" }}>
                &#8369; {props.product.regular_price}
              </strike>{" "}
            </>
          )}
          &#8369; {numberWithCommas(props.product.price)}
        </p>
      </CardBody>
    </Card>
  );
};

export default ProductCard;
