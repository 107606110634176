import React, { useState } from "react";
import {
  faCartShopping,
  faList,
  faSearch,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormInput,
  Collapse,
  Row,
  Col,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "shards-react";
import { Link, useNavigate } from "@reach/router";
import { useAppData } from "../context/AppContext";
import { ReactComponent as Lazada } from "../assets/images/bands/Lazada.svg";
import Shopee from "../assets/images/bands/Shopee.svg";

const Menu = () => {
  const { setRequestQuote, mobileScreen } = useAppData();

  const [account, setAccount] = useState(false);

  const navigate = useNavigate();

  const UPPER_MENU = [
    { label: "Home", link: "/" },
    { label: "About Us", link: "/about-us" },
    { label: "Catalog", link: "/catalog" },
    { label: "Contact Us", link: "/contact-us" },
    // {
    //   label: "Be a GCash Merchant",
    //   link: "/https://gate.com.ph/marketplace/be-a-gcash-merchant/",
    // },
    // { label: "Become a buyer", link: "/account" },
    // { label: "Become a reseller", link: "/reserler-account" },
  ];

  const SHOPS = [
    {
      shop: "Shopee",
      link: "https://shopee.ph/gateph",
      logo: "https://gate.com.ph/store/wp-content/uploads/2023/04/DaWub-e1680667402558.png",
    },
    {
      shop: "Lazada",
      link: "https://www.lazada.com.ph/shop/gate-distribution-ent-inc/",
      logo: "https://gate.com.ph/store/wp-content/uploads/2023/04/Lqwji-e1680667846629.png",
    },
    {
      shop: "Tiktok shop",
      link: "https://www.tiktok.com/@gateph",
      logo: "https://gate.com.ph/store/wp-content/uploads/2023/04/FJKw6.png",
    },
  ];

  const ShopsBTN = (props) => {
    return (
      <a href={props.shop.link} target="_blank">
        <Button
          theme="light"
          size="sm"
          style={{
            display: "inline-block",
            marginRight: 5,
            backgroundColor: "#fff",
          }}
          pill
        >
          <img src={props.shop.logo} style={{ width: "60px" }} />
        </Button>
      </a>
    );
  };

  return (
    <Navbar type="dark">
      <Row>
        <Col md={1} xs={4} sm={4} style={{ padding: 0 }}>
          <img
            src="https://gate.com.ph/wp-content/uploads/2022/05/long-logo.png"
            style={{ width: "100%", filter: "brightness(0) invert(1)" }}
            onClick={() => navigate("/")}
          />
        </Col>
        {!mobileScreen && (
          <Col md={5}>
            {UPPER_MENU.map((menu) => (
              <div style={{ display: "inline-block", marginRight: 15 }}>
                <a
                  href={menu.link}
                  style={{
                    margin: 0,
                    marginRight: 10,
                    color: "#fff",
                    textDecoration: "none",
                    fontWeight: 400,
                    fontSize: ".75rem",
                    textTransform: "uppercase",
                  }}
                  className={
                    menu.link === window.location.pathname
                      ? "nav-menu-bar menu-active"
                      : "nav-menu-bar"
                  }
                >
                  {menu.label}
                </a>
              </div>
            ))}
          </Col>
        )}

        <Col md={6} xs={8} sm={8} style={{ textAlign: "right", padding: 0 }}>
          {!mobileScreen && (
            <>
              {SHOPS.map((shop) => (
                <ShopsBTN shop={shop} />
              ))}

              <Button
                theme="light"
                size="sm"
                style={{
                  display: "inline-block",
                  marginRight: 5,
                  backgroundColor: "#fff",
                }}
                pill
                onClick={() => setRequestQuote(true)}
              >
                Request a Quote
              </Button>
            </>
          )}

          <Button
            size={mobileScreen ? "md" : "sm"}
            type="light"
            style={{
              backgroundColor: "#00315200",
              border: "1px solid #00315200",
              marginRight: 10,
            }}
          >
            <FontAwesomeIcon icon={faCartShopping} />
          </Button>

          <Dropdown open={account} toggle={() => setAccount(!account)}>
            <DropdownToggle
              size={mobileScreen ? "md" : "sm"}
              style={{
                backgroundColor: "#00315200",
                border: "1px solid #00315200",
                display: "inline-block",
                marginRight: 10,
              }}
            >
              <FontAwesomeIcon icon={faUser} />
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem>Login</DropdownItem>
              <DropdownItem>Profile</DropdownItem>
            </DropdownMenu>
          </Dropdown>

          {mobileScreen && (
            <Button
              size={mobileScreen ? "md" : "sm"}
              type="light"
              style={{
                backgroundColor: "#00315200",
                border: "1px solid #fff",
                // marginRight: 10,
              }}
            >
              <FontAwesomeIcon icon={faList} />
            </Button>
          )}
        </Col>
      </Row>
      {/* <Row>
        <Col md={2}>
            <img
              src="https://gate.com.ph/marketplace/wp-content/uploads/2023/02/XK3Un.png"
              style={{ width: "80%" }}
              onClick={() => navigate("/")}
            />
        </Col>
        <Col md={8}>
          <InputGroup>
            <FormInput
              placeholder="Search Products, Brands, Category..."
              onChange={(e) => setSearch(e.target.value)}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  setLoading(true) ||
                    getProducts(
                      {
                        search: search,
                      },
                      () => setLoading(false) || navigate("/search/products")
                    );
                }
              }}
            />
            <InputGroupAddon type="append">
              <Button
                onClick={() =>
                  setLoading(true) ||
                  getProducts(
                    {
                      search: search,
                    },
                    () => setLoading(false) || navigate("/search/products")
                  )
                }
              >
                <FontAwesomeIcon icon={faSearch} />
              </Button>
            </InputGroupAddon>
          </InputGroup>
        </Col>
        <Col md={2}>
          <Button
          size="sm"
            type="light"
            style={{
              backgroundColor: "#00315200",
              border: "1px solid #00315200",
              marginRight: 10,
            }}
          >
            <FontAwesomeIcon icon={faCartShopping} />
          </Button>
          <Dropdown open={account} toggle={() => setAccount(!account)}>
            <DropdownToggle
            size="sm"
              style={{
                backgroundColor: "#00315200",
                border: "1px solid #00315200",
                display: "inline-block",
              }}
            >
              <FontAwesomeIcon icon={faUser} />
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem>Login</DropdownItem>
              <DropdownItem>Profile</DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </Col>
      </Row> */}
    </Navbar>
  );
};

export default Menu;
